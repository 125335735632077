<template>
  <!-- 确认身份证信息 -->
  <div class="main-container">
    <p class="rem36 f500 c-303133 check-info">请检查您的发票信息</p>
    <div class="check-userinfo " v-for="(item, index) in invoiceList" :key="index">
      <van-field v-model="item.code" label="发票代码"> </van-field>
      <van-field v-model="item.no" label="发票号码" />
      <div v-if="item.showMore">
        <van-field
          label="开票日期"
          v-model="item.billDate"
          @click="showPickerFn(item, index)"
          readonly
        >
        </van-field>
        <van-field v-model="item.checkCode" label="校验码" />
        <van-field v-model="item.amt" type="number" label="发票金额" />
        <van-field v-model="item.taxAmt" type="number" label="发票税额" />
        <van-field v-model="item.excludingTaxAmt" type="number" label="不含税金额" />
        <van-field v-model="item.receiverName" label="受票方名称" />
        <van-field v-model="item.receiverTaxNo" label="受票方税号" />
        <van-field
          v-model="item.receiverAddrMobile"
          type="textarea"
          autosize
          label="受票方地址、电话"
        />
        <van-field
          v-model="item.receiverBankAccount"
          type="textarea"
          autosize
          label="受票方开户行、账号"
        />
        <van-field v-model="item.sellerName" label="销售方名称" />
        <van-field v-model="item.sellerTaxNo" label="销售方税号" />
        <van-field
          v-model="item.sellerAddrMobile"
          type="textarea"
          autosize
          label="销售方地址、电话"
        />
        <van-field
          v-model="item.sellerBankAccount"
          type="textarea"
          autosize
          label="销售方开户行、账号"
        />
      </div>

      <div class="c-4D7BFE mt8 flex align-center just-center" @click="showMoreFn(item)">
        更多信息
        <img :src="downPng" alt="" class="img16" v-if="!item.showMore" />
        <img :src="upPng" alt="" class="img16" v-else />
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        @cancel="showPicker = false"
        @confirm="checkTime"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
    <div class="mt40">
      <van-button round block type="info" @click="nextStep">下一步</van-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Cell, Button, Icon, DatetimePicker, Popup, Dialog } from 'vant'
import { listInvoiceDataApi, updateInvoiceApi, checkInvoiceApi } from '@/apis/borrow/index'
import moment from 'moment'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Field)
Vue.use(Cell)
Vue.use(Button)
Vue.use(Icon)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Dialog)
import { Encrypt, Decrypt } from '@/utils/crypto'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      invoiceList: [],
      downPng: require('@/assets/images/borrow/down.png'),
      upPng: require('@/assets/images/borrow/up.png'),
      codetext: '获取验证码',
      isCompanyEmpty: false, // 用户是否拥有企业
      showPicker: false,
      current: '', // 当前项
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2100, 10, 1),
    }
  },
  created() {
    this.getInvoiceList()
  },
  computed: {
    ...mapState({
      loanNo: state => state.borrow.loanNo,
    }),
  },
  methods: {
    getInvoiceList() {
      listInvoiceDataApi({
        loanNo: this.loanNo,
      }).then(res => {
        if (res.code == 200) {
          this.invoiceList = res.data
          this.invoiceList.forEach(item => {
            if (item.billDate) item.billDate = item.billDate.substr(0, 10)
            this.$set(item, 'showMore', false)
          })
        }
      })
    },
    showMoreFn(item) {
      item.showMore = !item.showMore
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },
    hideloading() {
      this.$cancelLoading()
    },
    nextStep() {
      let reg = /^((([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?)$/
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (!reg.test(this.invoiceList[i].amt)) {
          this.$common.toast(
            `发票代码为${this.invoiceList[i].code}的发票金额应为正数，且最多两位小数`
          )
          return
        }
        if (!reg.test(this.invoiceList[i].taxAmt)) {
          this.$common.toast(
            `发票代码为${this.invoiceList[i].code}的发票税额应为正数，且最多两位小数`
          )
          return
        }
        if (!reg.test(this.invoiceList[i].excludingTaxAmt)) {
          this.$common.toast(
            `发票代码为${this.invoiceList[i].code}的不含税金额应为正数，且最多两位小数`
          )
          return
        }
      }
      this.showloading()
      updateInvoiceApi(this.invoiceList)
        .then(res => {
          if (res.data.isSuccess == 0) {
            checkInvoiceApi({
              loanNo: this.loanNo,
            }).then(result => {
              // 验真失败三次，流程结束
              if (result.data.failTimes > 2) {
                this.hideloading()
                Dialog.alert({
                  title: '提示',
                  message: `发票代码:${result.data.fpdm}校验不通过，请检查发票信息是否准确。（${result.data.message}）`,
                  confirmButtonText: '知道了',
                })
                this.$router.push('/approvel-refuse-result')
                return
              }
              // 不足显示，显示报错信息
              if (!result.data.success) {
                this.hideloading()
                Dialog.alert({
                  title: '提示',
                  message: `发票代码:${result.data.fpdm}校验不通过，请检查发票信息是否准确。`,
                  confirmButtonText: '知道了',
                })
              }
              // 验真成功到上传凭证页
              if (result.data.success) {
                this.hideloading()
                this.$router.push('/upload-certificate')
              }
            })
          } else if (res.data.isSuccess == -1 && res.data.repeatCode.length == 0) {
            this.hideloading()
            this.$common.toast(res.data.message)
          } else if (res.data.isSuccess == -1 && res.data.repeatCode.length > 0) {
            this.hideloading()
            let errorCode = res.data.repeatCode.join('、')
            Dialog.alert({
              title: '提示',
              message: `发票代码:${errorCode}文件重复，请重新确认。`,
              confirmButtonText: '知道了',
            })
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },
    showPickerFn(item, index) {
      this.current = index
      this.showPicker = true
    },
    checkTime(value) {
      this.invoiceList[this.current].billDate = moment(value).format('YYYY-MM-DD')
      this.showPicker = false
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
  padding-bottom: 0.12rem;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 0.32rem;
}
.check-info {
  margin-bottom: 0.4rem;
}
.check-userinfo {
  padding: 0.02rem 0.28rem 0.28rem 0.4rem;
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.08rem;
  margin-bottom: 0.2rem;
  .check-color {
    /deep/ .van-field__control {
      color: #b9bcc2 !important;
    }
  }
}
/deep/ .van-icon__image {
  width: 0.52rem;
  height: 0.52rem;
}
.address {
  /deep/ .van-field__label {
    width: 2.7rem;
  }
}
</style>
